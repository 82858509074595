.circle-container {
    display: flex;
  
    justify-content: center;
    align-items: center;
  
    width: 100%;
    height: 100%;
  }
  
  .circle-svg {
    --_circle-radius: 45px;
    --_svg-height: 50%;
    --_stroke-width: 10px; /* svg-width - (circle-radius * 2) */
  
    height: var(--_svg-height);
  
    stroke: white;
    /*   stroke-linecap: round; */
    stroke-width: var(--_stroke-width);
  
    stroke-dashoffset: 1;
    stroke-dasharray: 1 1000;
  
    animation: loader 1500ms infinite linear, spin 3000ms infinite linear;
  }
  
  @keyframes loader {
    0% {
      stroke-dashoffset: 1;
      stroke-dasharray: 1 1000;
    }
  
    100% {
      stroke-dashoffset: calc(var(--_circle-radius) * -2 * 3.1415);
      stroke-dasharray: calc(var(--_circle-radius) * 2 * 3.1415) 1000;
    }
  }
  
  @keyframes spin {
    0% {
      rotate: 0deg;
    }
  
    100% {
      rotate: 360deg;
    }
  }
  