    .instantShareInner h3{
        font-family: Poppins;
        font-size: 13px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0em;
        text-align: center;
        margin-bottom: 8px;
        color: white;
    }
    .instantShareInner p{
        font-family: Poppins;
        font-size: 11px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0em;
        text-align: center;
        color: #FFFFFF;
    }
    .SuccessSubsImageDiv{
        width: 100%;
        text-align: center;
    }
.instantShareInner .SuccessSubsImage{
    margin: 0px auto;
    width: 150px;
    margin-bottom: 24px;
}
