.drawer-li {
  font-size: 20px;
  display: block;
  text-decoration: none;
  color: white !important;
  margin-bottom: 10px;
}
.drawer-SideStyleButton {
  background: #007aff;
  border-radius: 5px;
  border: 0px;
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  width: 160px;
  color: #fff;
  margin-top: 15px;
}
.ant-drawer-wrapper-body {
  background: #242429;
  color: white !important;
}
.ant-drawer-header {
  border-bottom: 1px solid #f0f0f057;
}
.ant-drawer-header-title svg {
  fill: white;
}
