.ant-dropdown-menu,
.ant-dropdown {
  /* background-color: transparent !important; */
  z-index: 100000 !important;
  /* border-radius: 10px; */
  /* overflow: auto; */
  background: #242429 !important;
  color: white;
  /* z-index: 99; */
  box-shadow: 0px 0px 10px -4px black;
  /* padding: 10px 0px; */
}
.ant-dropdown-menu-item {
  color: white !important;
}
.ant-dropdown-menu-item:hover{
  color: black !important;

}
.ant-dropdown-menu-item-selected {
  color: black !important;
}
.ant-notification-notice-icon {
  display: none !important;
}
.ant-notification-notice-description {
  margin: 0px !important;
}
.NotiDes {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}
.NotiDes {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
}
.NotiDes svg {
  margin-right: 10px;
}

.ant-notification-notice-close-icon {
  color: white;
}
