button:disabled {
    cursor: not-allowed !important
}

.upgradePlanToast {
    cursor: pointer
}

.upgradePlanToast:hover {
    text-decoration: underline
}

.optionName {
    color: #fff !important
}

.optionName.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #303036 !important
}

.optionName.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: #303036 !important
}

.ant-select-selection-item {
    display: flex
}

.optionName .optionText {
    display: flex;
    align-items: center;
    justify-content: space-between
}

.optionText p {
    text-overflow: ellipsis;
    margin-bottom: 0 !important;
    overflow: hidden;
    white-space: nowrap;
    width: 85%
}

.optionMark {
    display: none
}

.optionName .optionMark {
    width: 20.5px;
    height: 20.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #007aff;
    border-radius: 4px
}

.optionName.ant-select-item-option-active:not(.ant-select-item-option-disabled) .optionMark {
    background: #007aff
}

.optionName.ant-select-item-option-active:not(.ant-select-item-option-disabled) .optionMark svg {
    visibility: visible
}

.optionName.optionName.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .optionMark {
    background: #007aff
}

.optionName.optionName.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .optionMark svg {
    visibility: visible
}

.optionName .optionMark svg {
    visibility: hidden
}

.optionsList {
    display: none;
    flex-direction: column;
    position: absolute;
    width: 100%;
    top: 40px;
    border-radius: 10px;
    height: 200px;
    overflow: auto;
    background: #242429;
    z-index: 99;
    box-shadow: 0 0 10px -4px #000;
    padding: 10px 0
}

.optionsList.active {
    display: flex
}

.optionName {
    color: #fff !important;
    padding: 2px 10px;
    cursor: pointer
}

.noResultText {
    text-align: center;
    align-items: center;
    justify-content: center;
    color: #ffffffb5;
    padding-top: 10px;

}

.optionName:hover {
    background-color: #0000002e !important
}

.ant-select-selection-item {
    display: flex
}

.optionName .optionText {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 11px;
}

.optionText p {
    text-overflow: ellipsis;
    margin-bottom: 0 !important;
    overflow: hidden;
    white-space: nowrap;
    width: 85%
}

.optionMark {
    display: none
}

.optionName .optionMark {
    width: 20.5px;
    height: 20.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #007aff;
    border-radius: 4px
}

.optionName .optionMark.active {
    background: #007aff
}

.optionName .optionMark.active svg {
    visibility: visible
}

.optionName .optionMark.active {
    background: #007aff
}

.optionName .optionMark.active svg {
    visibility: visible
}

.optionName .optionMark svg {
    visibility: hidden
}

.ant-dropdown {
    z-index: 100000 !important
}

.ant-message {
    z-index: 10100000 !important
}

.disabledLayer {
    background-color: #fff;
    width: 100%;
    height: 100%;
    background-color: #ffffff00;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    cursor: not-allowed;
    backdrop-filter: blur(2px);
    top: 0;
    left: 0
}

.ant-popover {
    z-index: 100001 !important
}

.Toastify {
    z-index: 1.0E+18;
    position: absolute
}

.loadingDivPage {
    height: 350px
}

.loadingDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column
}

.refreshText {
    text-align: center;
    color: #fff;
    font-size: 18px;
    margin-bottom: 20px
}

.loadingCircle {
    width: 85px;
    height: 85px;
    border: 5px solid #007aff;
    border-radius: 90px;
    border-left: 5px solid transparent;
    animation: refreshRound 1s infinite
}

.playlistRefrestText {
    font-size: 17px !important;
    margin-bottom: 10px
}

.playlistloadingCircle {
    width: 35px !important;
    height: 35px !important;
    border: 3px solid #007aff;
    border-left: 5px solid transparent
}

button {
    cursor: pointer !important
}

.positionToolTip {}

.confirmButton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-top: 20px
}

.confirmButton button {
    background: #007aff;
    padding: 2px 11px;
    border: none;
    border-radius: 10px
}

.filterDropDown {
    background-color: transparent !important;
    background: transparent !important;
}

.dropdownFilter {
    padding: 16px;
    border-radius: 10px;
    border: 1px solid #FFFFFF4D;
    box-shadow: 4px 4px 48px 0px #FFFFFF0A;
    background-color: #303036;
    width: 278px;

}

.dropdownFilter .inputLabel {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
    width: 100%;
}

.dropdownFilter .filterSelect {
    background-color: transparent !important;
    background: transparent !important;
    border: 0.6px solid #FFFFFF33 !important;
    border-radius: 8px !important;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
    margin-top: 8px;
    position: relative;

    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
    margin-top: 8px;
    border: 0.6px solid #FFFFFF33;
    border-radius: 8px;
    cursor: pointer;

    min-height: 31.46px;


}

.dropdownFilter .filterSelect .playlistValue {
    padding: 4px 11px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;

    text-overflow: ellipsis;
    margin-bottom: 0 !important;
    overflow: hidden;
    white-space: nowrap;
    width: 85%;
}

.dropdownFilter .filterSelect .playlistValue.playlistInputText {
    padding: 5px 10px;

    min-height: 30px;
    width: calc(100% - 10px);
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.dropdownFilter .filterSelect .playlistValue.playlistInputText span {
    background: #ffffff2e;
    border-radius: 4px;
    padding: 2px 10px;
    margin-right: 10px;
    white-space: nowrap;
}

.dropdownFilter .filterSelect .playNames {
    padding: 4px 11px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;

    text-overflow: ellipsis;
    margin-bottom: 0 !important;
    overflow: hidden;
    white-space: nowrap;
    /* width: 85%; */
}

.dropdownFilter .filterSelect .playNames:hover {
    background-color: #0000002e !important;
}

.dropdownFilter .filterSelect .playNames.active {
    background-color: #0000002e !important;

}

.dropdownFilter .ant-select-selector {

    background-color: transparent !important;
    background: transparent !important;
    border: 0.6px solid #FFFFFF33 !important;
    border-radius: 8px !important;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
}

.filterDropDownMenuSelect {
    z-index: 100000 !important;
    position: absolute;
    overflow: auto;
    box-shadow: 4px 4px 48px 0px #FFFFFF0A;
    background-color: #303036;
    border-radius: 10px;
    width: 100%;
    height: 0px;
    display: none;
    top: 31.46px;
}

.filterDropDownMenuSelect.active {
    height: auto;
    max-height: 224px;
    display: block;
    border: 1px solid #FFFFFF4D;
}

.filterDropDownMenuSelect.ant-select-dropdown {
    border: 1px solid #FFFFFF4D;
    box-shadow: 4px 4px 48px 0px #FFFFFF0A;
    background-color: #303036;
}

.filterDropDownMenuSelect .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: transparent !important;
}

.filterDropDownMenuSelect .ant-select-item-option-selected {
    background: transparent;
    background-color: transparent;
}

.filterDropDownMenuSelect .ant-select-item-option-content {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
}

.dropdownFilter .inputDiv {
    margin-bottom: 13px;
}

.dropdownFilter .ant-input {
    background-color: transparent;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
    margin-top: 8px;
    border: 0.6px solid #FFFFFF33;
    border-radius: 8px;
}

.newStylePopup {
    border: 1px solid #FFFFFF4D;
    border-radius: 10px;
    padding: 0px;
    overflow: hidden;
    padding: 5px 12px;
    background-color: #242429;
}

.newStylePopup .ant-popover-inner {
    box-shadow: none !important;
}

.newStylePopup .ant-popover-arrow,
.ant-tooltip-arrow {
    display: none;
}

.newStylePopup .ant-popover-title {
    background: #242429 !important;
    padding-top: 8px;
    padding-bottom: 8px;
}

.newStylePopup .ant-popover-inner-content {
    background: #242429 !important;
}

.newStylePopup .ant-tooltip-inner {
    background-color: #242429;
    box-shadow: none !important;
}

.applyFilterButtonDiv {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
}

.applyFilterButton {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    background: rgb(0, 122, 255);
    padding: 0px 30.;
    height: 38px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    width: 100%;
}

.resetFilterButton {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 0px 30.;
    height: 38px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    background-color: transparent;
    color: white;
    width: unset;
}

.innerFilterBox .ant-space {
    display: flex;
    align-items: center;
    justify-content: center;
}

.innerFilterBox .ant-space-item {
    display: flex;
    align-items: center;
}

.dropdownFilter .datePickerFilter {
    background: transparent;
    border: 0.6px solid #FFFFFF33;
    border-radius: 8px;
    height: auto;
    margin-top: 8px;
}

.dropdownFilter .datePickerFilter input {
    color: white;
    font-size: 12px;
}

.ant-pagination-item-ellipsis {
    color: white !important;
}

.ant-popover-inner {
    border-radius: 15px !important;
}

.ant-popover-inner-content {
    overflow: hidden;
    border-radius: 10px !important;
}

.ant-popover-inner-content p, .ant-popover-inner-content .popSpan {
    font-weight: 700 !important;
    font-size: 10px;
}

.load-wrapp {
    display: flex;
    align-items: center;
    justify-content: center;
}

.scroll-container {
    background-color: white;
    position: fixed;
    bottom: 500px;
}

.page-content:has(> div.playlistFIxed) {
    padding-top: 140px;
    /* margin-bottom: 120px; */
}

.playlistPageContainer {
    height: calc(100vh - 10px);
    padding-bottom: 120px;
    overflow: auto;

}

.playlistPageContainer.playlistFIxed {
    height: calc(100vh - 140px - 10px);
    padding-bottom: 120px;
}

.dropPosition>td {
    border-bottom: 3px solid #007aff;
}

.restoreButton svg {
    width: 22px;
    height: 22px;
}

.carousel-container {
    cursor: grab;
}

/* 
.playlistPageContainer::-webkit-scrollbar {
    width: 0px !important;
    display: none;
}


.playlistPageContainer::-webkit-scrollbar-track {
    background: #f1f1f1;
}


.playlistPageContainer::-webkit-scrollbar-thumb {
    background: #888;
}


.playlistPageContainer::-webkit-scrollbar-thumb:hover {
    background: #555;
} */
.dropDownDashboardOverlay {
    background-color: #242429 !important;
    border: 1px solid #FFFFFF4D !important;
    border-radius: 5px;
    left: auto !important;
    right: 110px;
    top: 30px !important;
    width: 247px;

}

.dropDownDashboardOverlay ul {
    /* border: 1px solid white !important; */
    border-radius: 5px;
    padding: 8px 20px;
    border-radius: 11px;
}

.dropDownDashboardOverlay .ant-dropdown-menu-title-content {
    width: 100%;
}

.dropDownDashboardOverlay .ant-dropdown-menu-item:first-child {
    padding: 10px;

}

.dropDownDashboardOverlay .ant-dropdown-menu-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.30);
    padding: 10px 10px;
    /* margin: 2px 0px; */
    border-radius: 5px;
}

.dropDownDashboardOverlay .ant-dropdown-menu-item:last-child {
    border: none;
}

.dropDownDashboardOverlay .ant-dropdown-menu-item:hover {
    background-color: #007aff !important;
}

.dropDownDashboardOverlay .ant-dropdown-menu-item:hover {
    color: white !important;
}

.avDropDown {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 28.8px;
    text-align: left;
}

.accName {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 28.8px;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

}

.accEmail {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

}

.currnecyropDownOverlay .ant-dropdown-menu-title-content {
    font-size: 12.53px;
}

.ant-picker-panel-container {
    font-size: 12px;
}

.ant-tooltip-inner {
    font-size: 12px;
}

.ant-popover-content {
    width: fit-content;
    max-width: 239px;
}

.ant-popover-message-icon {
    font-size: 12px;
}

.ant-popover-message-title {
    font-size: 12px;
    color: white;
}

.ant-popover-content .ant-popover-buttons {
    font-size: 12px;

}


.scheduleInput {
    height: 28px !important;
    font-size: 10px;
    background-color: #D9D9D9;
}

.scheduleInput input {
    font-size: 10px;
    color: black;
    font-weight: 800;
}

.schedlepopConfirm .ant-popover-buttons {
    display: none;
}

.schedlepopConfirm .ant-btn {
    font-size: 10px;
}

.scheduleInput .ant-picker-suffix svg {
    color: black;
}

.schedlepopConfirm .ant-popover-inner {
    background-color: transparent !important;
}

.schedlepopConfirm .ant-popover-inner-content {
    background: rgb(36, 36, 41) !important;
    color: white !important;
    border: 1px solid #FFFFFF33;
    border-radius: 8px;
    padding: 24px 32px;
}

.schedlepopConfirm .ant-popover-content {
    width: 284px;
}

.schedlepopConfirm .ant-popover-message {
    padding: 0px;
}

.schedlepopConfirm .ant-popover-message-title {
    width: 100%;
}

.ant-popover-buttons button {
    font-size: 12px;
}

.copyPop .ant-popover-inner {
    width: fit-content;
}

.ant-popover-inner{
    background: #202020;
}
p:first-letter, h1:first-letter, h2:first-letter, h3:first-letter, h4:first-letter, h5:first-letter, h6:first-letter, a:first-letter, button:first-letter {
    text-transform: uppercase;
}
