.SubscriptionModal{
    max-width: 486px !important;
    width: 100% !important;
}
.SubscriptionModal .ant-modal-content{
    background-color: #242429;
    border: 1px solid #FFFFFF4D;
    border-radius: 20px;
    color: white;
    padding: 40px 50px ;
}
.SubscriptionModal  .ant-modal-body{
    padding: 0px;
}
.innerSubscriptionModal{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.innerSubscriptionModal .cancelSubsImage{
    margin-bottom: 40px;
    width: 134px;
    
    
}
.SuccessSubsImage{
    margin-bottom: 40px;
    width: 170px; 
}
.innerSubscriptionModal .SubscribeBtn{
    background: #007AFF;
    color: white;
    width: 100%;
    padding: 15px 0px;
    font-family: Poppins;
    font-size: 11px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0em;
    text-align: center;
    border-radius: 8px;
    border: none;
    width: 100%;
    margin-top: 20px;
}
.innerSubscriptionModal .loginBtn{
    background: #505050;
    margin-top: 20px;
    color: white;
    width: 100%;
    padding: 15px 0px;
    font-family: Poppins;
    font-size: 11px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0em;
    text-align: center;
    border-radius: 8px;
    border: none;
    box-shadow: 0px 2.3556711673736572px 2.3556711673736572px 0px #0000002B;

box-shadow: 0px 0px 2.3556711673736572px 0px #00000015;
border: 0.97px solid #FFFFFF4D
}
.innerSubscriptionModal h3{
    font-family: Poppins;
font-size: 13px;
font-weight: 500;
line-height: normal;
letter-spacing: 0em;
text-align: center;
margin-bottom: 8px;
color: white;
}
.innerSubscriptionModal p{
    font-family: Poppins;
font-size: 11px;
font-weight: 400;
line-height: normal;
letter-spacing: 0em;
text-align: center;
color: #FFFFFF;


}
.positionWarning{
    color: red !important;
    margin-top: 8px;
}

.dontShow{
    margin-top: 14px;
    display: flex;
    align-items: center;
    font-size: 12px;
}
.dontShow input{
    margin-right: 4px;
    background: transparent;
    background-color: transparent;
    width: 15px;
    height: 15px;
}